import React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import Contact from '../components/Contact/Contact'
import CallToAction from '../components/CallToAction/CallToAction'

const ContactPage = () => {
  return (
    <>
    <Seo title="Contact Katia" />
    <Layout>
      <Contact />
      <CallToAction header="Go back to homepage" text="See my work" to="/" />
    </Layout>
    </>
  )
}

export default ContactPage

