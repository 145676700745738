import React from "react"
import styled from "styled-components"
import ContactForm from '../ContactForm/ContactForm'
import { Phone, Mail } from 'react-feather'

const ContactWrapper = styled.div`
    text-align: center;
    margin: 160px auto 80px auto;

    @media screen and (min-width: 1080px) {
        margin: 80px auto 0 auto;
    }
`

const ShortContact = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1080px;
    margin: 0 auto;

    @media screen and (min-width: 1080px) {
        grid-template-columns: 1fr;
    }

`

const ShortContactItem = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    a, 
    p {
        color: var(--black);
        display: block;
        font-size: 1rem;
        margin-left: 10px;
        font-size: 1.414rem;
        padding-top: 10px;
        padding-bottom: 10px;

    }
`

const Contact = () => {

    return (
        <ContactWrapper>
            <h1>Contact Katia Ehlert</h1>

            <ShortContact>
                <ShortContactItem>
                    <Phone /><a href="tel:+34600317406">+34 600 31 74 06</a>
                </ShortContactItem>
                <ShortContactItem>
                    <Mail /><a href="mailto:katia.ehlert@gmail.com">katia.ehlert@gmail.com</a>
                </ShortContactItem>
            </ShortContact>
            <ContactForm />
        </ContactWrapper>
    )
   
}

export default Contact