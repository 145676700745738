import React from 'react'
import * as classes from './ContactForm.module.css'

const ContactForm = () => {
  return (
    <div className={classes.section}>
      <form name="Contact Form" method="POST" data-netlify="true" action="/messages/thanks/">
        <input type="hidden" name="form-name" value="Contact Form" />
        <div className={classes.formContainer}>
          <div className={classes.inputContainer}>
            <label htmlFor="name">Your Name:</label>
            <input type="text" name="name" placeholder="Enter your Name" required/>
          </div>
          <div className={classes.inputContainer}>
            <label htmlFor="email">Your Email:</label>
            <input type="email" name="email" placeholder="Enter your email" required/>
          </div>
          <div className={classes.inputContainer}>
            <label htmlFor="message">Message:</label>
            <textarea name="message" placeholder="Enter a message" required/>
          </div>
        </div>
        <div>
          <button className={classes.button} type="submit">
            Send
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
